import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import Gallery from "../components/Gallery"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulObject {
        edges {
          node {
            year
            title
            medium
            image {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
              file {
                fileName
              }
            }
            dimensions
          }
        }
      }
    }
  `)
  return (
    <Layout title="objects">
      <Gallery props={data}></Gallery>
    </Layout>
  )
}
